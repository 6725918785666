%table-generic-styles {
  border: 0;
  margin-bottom: 40px;
  width: 100%;

  thead {
    background-color: transparent;
  }

  // Table columns size
  th {
    &.s-50 {
      width: 50%;
    }

    &.s-45 {
      width: 45%;
    }

    &.s-40 {
      width: 40%;
    }

    &.s-35 {
      width: 35%;
    }

    &.s-30 {
      width: 30%;
    }

    &.s-25 {
      width: 25%;
    }

    &.s-20 {
      width: 20%;
    }

    &.s-15 {
      width: 15%;
    }

    &.s-10 {
      width: 10%;
    }
  }

  td {
    ul {
      list-style: none;
      padding: 0;
    }

    i {
      margin: 0 5px;
      vertical-align: middle;
    }

    &.vtop {
      vertical-align: top;
    }

    &.description {
      font-family: $roboto;
      font-weight: 300;
    }
  }

  .one-line {
    white-space: nowrap;
  }

  // when tables include form elements, reset bottom margin
  .pure-field:last-child {
    margin-bottom: 0;
  }

  .row-pure-button .pure-field {
    margin-top: 5px;
  }
}

.table-container {
  overflow: scroll;
}

.pure-table {
  @extend %table-generic-styles;

  th,
  td {
    border: 0;
    border-bottom: 1px solid $tbl-border;
    font-size: calc-font-size(16px);
  }

  th {
    color: $th-color;
    font-weight: normal;

    label::first-letter {
      text-transform: uppercase;
    }

    &.orderable a {
      text-decoration: none;

      &::after {
        border-color: $th-color transparent transparent;
        border-style: solid;
        border-width: 8px 9px 0;
        content: '';
        display: inline-block;
        height: 0;
        margin-left: 12px;
        position: relative;
        top: -1px;
        width: 0;
      }
    }
  }

  td {
    padding: 20px;
  }

  & + .pure-pag {
    margin-top: -20px;
  }

  &.pure-table-alt {
    th,
    td {
      border: 0;
    }

    tbody tr {
      background-color: $tbl-drk-strip;
      border-bottom: 4px solid $white;

      &:last-child {
        border: 0;
      }
    }
  }

  & .pure-table {
    margin-bottom: 0;

    .form-add {
      padding: 0;
    }
  }
}

.pure-table-dark {
  @extend %table-generic-styles;

  th,
  td {
    border-left: 4px solid $btn-color;
  }

  th {
    background-color: $tbl-strip;
    border-bottom: 18px solid $btn-color;
    font-weight: normal;
    padding: 8px 55px 6px;

    &:empty {
      background-color: transparent;
    }
  }

  td {
    padding: 21px 56px;
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: $tbl-drk-strip;
      }
    }
  }
}

.pure-table-striped {
  tr {
    &:nth-child(odd) td {
      background-color: $tbl-strip;
    }

    td {
      border-bottom: 0;
    }
  }
}
