// generic colors (alternative theme)
$evote-default: #7c393f;
$dark-bg: #2f3242; // rgb(47,50,66)
$alt-dark-bg: #80171b;
$txt-color: #2c323f; // rgb(44, 50, 63)

// Titles
$hd-color: #84454b; // rgb(63,67,89)
$hd-scnd-color: #cda7a9; // rgb(127,150,184)
$hd-block-bg: #a96568;

// Menus
$lightgloss: rgba(255, 255, 255, 0.7);
$footer-bg-color: #ccc;
$footer-link-color: #83474d;

// button colors
$btn-font-bg: $evote-default;
$btn-color: $white;
$btn-bg: #ba8588;
$btn-border: $btn-bg;

$btn-hover-color: $btn-color;
$btn-hover-bg: #a07476;
$btn-hover-border: $btn-hover-bg;

$btn-inverted-color: $btn-bg;
$btn-inverted-bg: $btn-color;
$btn-inverted-border: $btn-bg;

$btn-inactive-color: #bbb;
$btn-inactive-bg: #eee;
$btn-inactive-border: $btn-inactive-color;

// icons
$ico-success: #a3d900;
$ico-noinfo: #aaa;
$ico-inactive: #ddd;

// form colors
$frm-input-border: $btn-border;
$frm-input-color: $dark-bg;
$frm-input-label: $hd-scnd-color;
$frm-input-focus: $btn-hover-border;
$frm-input-error: #ff7373;
$frm-input-success: #a3d900;

// tabs
$tab-bg: #d0a7ae;
$tab-acc: #ddd;
$tab-acc-title: $hd-color;

// alerts
$alert-error: #ff7373;
$alert-dk-error: #ff5959;
$alert-help: #d0b1b6;
$alert-dk-help: $tab-bg;
$alert-info: #966065;
$alert-dk-info: $hd-block-bg;
$alert-warning: #e8ac3c;
$alert-dk-warning: #dfa941;

// table colors
$th-color: #b47a7c;
$tbl-border: $th-color;
$tbl-strip: $alert-help;
$tbl-drk-strip: #f6f6f6;

// window
$window-bg: #7c393f;
$window-color: #5f6d88;

// Admin
$admin-footer-bg: #e4eaf1;
$admin-menu-bg: #e4eaf1;
$admin-sub-menu-bg: $admin-footer-bg; // rgb(127,150,184)
$admin-sub-menu-text: $dark-bg;

// Search
$search-bottom-border: #80171b;
