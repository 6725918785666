// Tablet
@include media-breakpoint-between(md, xl) {
  main {
    .electoralprocess-list-grid {
      .ev-top-actions .mobile-no-float {
        margin-left: 7px;
        width: min-content;
      }
    }
  }
}

// Mobile
@include media-breakpoint-between(xs, md) {
  ul.wrap {
    margin-left: 0;
    margin-right: 0;
    max-width: 1280px;
    padding-left: 15px;
    padding-right: 15px;
  }

  main {
    .electoralprocess-list-grid {
      h1 {
        text-align: center;
      }

      .ev-top-actions {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;

        .mobile-no-float {
          float: none;
          height: min-content;
          width: min-content;
        }

        .mobile-no-float a {
          width: 100%;
        }

        form {
          float: none;
          padding-left: 10px;

          .electoralprocess-list-grid {
            flex-direction: column;
          }
        }
      }
    }

    .table-container .pure-table td {
      font-size: 14px;
      padding: 10px;

      i {
        margin: 0;
      }

      a {
        min-width: min-content;
        padding: 8px 15px;
      }
    }
  }
}
