& {
  $font-mult: 1.3 !global;
  font-size: calc-font-size(16px);
}

.pure-g label,
.pure-form label {
  margin: 0;
  white-space: normal;
}

.pure-form .pure-checkbox {
  margin: 0.5em 0;
}

.pure-sec-block .pure-sec-body .pure-sec-row.with-button .main-label {
  line-height: normal;
}

.pure-button {
  white-space: normal;
}

&.pure-window .pure-window-header .pure-window-close.pull-right div,
.remodal .remodal-close div {
  margin-top: 60px;
}

&.homepage .site-header .pure-menu .selected-menu {
  top: -10px;
}

.message-list .message:hover label {
  color: $alert-dk-info;
}

.site-footer .pure-menu .pure-menu-item .pure-menu-link {
  min-width: auto;
  padding: 10px 6px;
}
