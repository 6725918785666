.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.clear {
  clear: both;
}

.full-width {
  width: 100%;
}

.cf {
  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }
}

.vertical-align {
  display: table-cell;

  & > i {
    vertical-align: middle;
  }
}

.vhidden {
  visibility: hidden;
}
