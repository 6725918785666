.contacts {
  h1 {
    margin-bottom: 29px;
  }

  .pure-description {
    margin-bottom: 25px;
  }

  label.captcha {
    margin-bottom: 7px;
    text-transform: uppercase;
  }

  .contacts-info {
    margin-bottom: 25px;

    .pure-description {
      margin-bottom: 32px;
    }

    .contact-line {
      margin-bottom: 10px;
    }
  }

  #contacts-map {
    height: 300px;
    width: 100%;
  }

  // Mobile
  @include media-breakpoint-between(xs, md) {
    h1 {
      text-align: center;
    }

    .contacts-mobile-grid {
      .contacts-description {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
      }

      .contacts-mobile-grid {
        align-items: center;
        justify-content: center;
        margin-bottom: 75px;

        .contacts-form {
          display: flex;
          flex-direction: column;

          .contacts-form-button {
            align-self: center;
            justify-self: center;
          }

          .captcha {
            margin-bottom: 10px;
            text-align: center;
          }

          #id_contacts-captcha {
            align-items: center;
            display: flex;
            justify-content: center;
          }
        }
      }

      .contacts-info {
        margin-bottom: 5px;
        padding: 25px;
        padding-bottom: 20px;
      }

      #contacts-map {
        margin: auto;
        margin-bottom: 25px;
        width: 90%;
      }
    }
  }
}
