.site-footer {
  .pure-menu {
    background-color: $footer-bg-color;
    height: 60px;

    .footer-menu {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    .pure-menu-item {
      &:first-child .pure-menu-link {
        padding-left: 0;
      }

      &:last-child .pure-menu-link {
        min-width: auto;
        padding-right: 0;
      }

      .pure-menu-link {
        color: $footer-link-color;
        font-size: calc-font-size(14px);
        min-width: 138px;
        padding: 10px 20px;

        &:hover,
        &:focus {
          background-color: transparent;
          color: $btn-color;
        }
      }
    }
  }

  .copyright {
    color: $dark-bg;
    font-size: calc-font-size(12px);
    font-weight: 300;
    text-align: center;

    small {
      margin-left: 10px;
    }
  }

  .copyright-manager {
    color: $lightgloss;
    font-size: calc-font-size(12px);
  }

  // Tablet
  @include media-breakpoint-between(md, xl) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;

    .pure-menu {
      height: 100%;

      .footer-menu {
        position: initial;
        top: 0;
        transform: none;

        .pure-menu-list {
          align-items: center;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          padding-bottom: 20px;
          padding-top: 20px;
        }

        .pure-menu-list li {
          padding: 10px;

          a {
            padding: 0;
            text-align: center;
          }
        }
      }
    }
  }

  // Mobile
  @include media-breakpoint-between(xs, md) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .pure-menu {
      height: 100%;

      .footer-menu {
        position: initial;
        top: 0;
        transform: none;

        .pure-menu-list {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 20px;
          padding-top: 20px;
        }

        .pure-menu-list li {
          padding: 10px;

          a {
            padding: 0;
            text-align: center;
          }
        }
      }
    }
  }
}
