%pure-alert-generic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 6px;
  padding: 9px 20px 9px 58px;
  position: relative;

  &::before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 42px;
  }

  .ev-icon {
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.pure-alert {
  @extend %pure-alert-generic;

  background-color: $alert-error;
  color: $btn-color;

  &::before {
    background-color: $alert-dk-error;
  }
}

.pure-help {
  @extend %pure-alert-generic;

  background-color: $alert-help;

  &::before {
    background-color: $alert-dk-help;
  }

  .evi-msg {
    margin: 2px 0 0 7px;
  }
}

.pure-warning {
  @extend %pure-alert-generic;

  background-color: $alert-warning;
  color: $btn-color;

  &::before {
    background-color: $alert-dk-warning;
  }

  a {
    color: $white;
  }
}

.pure-success {
  @extend %pure-alert-generic;

  background-color: $alert-info;
  color: $btn-color;

  &::before {
    background-color: $alert-dk-info;
  }
}

.pure-info {
  @extend %pure-alert-generic;
  @include border-radius(4px);
  background-color: $alert-dk-info;
  color: $btn-color;
  min-height: 47px;
  padding: 18px 36px 18px 122px;

  &::before {
    @include border-radius(4px 0 0 4px);
    background-color: $alert-info;
    width: 86px;
  }

  .evi-info {
    margin-left: 19px;
  }
}

.pure-help-info {
  @extend %pure-alert-generic;
  background-color: $alert-help;
  display: block;
  padding-bottom: 15px;
  padding-top: 16px;

  b {
    display: inline-block;
  }

  .ev-icon {
    margin-left: 10px;
  }
}

.globalalert {
  background-color: $global-alert-bg;
  color: $white;
  font-family: $roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 50px;
  margin-bottom: 15px;
  margin-top: -15px;
  text-align: center;
}
