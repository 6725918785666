// sass-lint:disable no-important

.personal-save {
  align-items: center;
  display: flex;
  justify-content: center;

  button {
    margin-top: 1rem;
  }
}

// Mobile & Tablet
@include media-breakpoint-between(xs, xl) {
  main {
    .personal-title-container {
      margin-left: 0 !important;
      margin-right: 0 !important;

      .personal-title {
        text-align: center;
      }
    }

    .personal-fieldarea {
      align-items: center;
      justify-content: center;
      margin-left: 0 !important;
      margin-right: 0 !important;

      .pure-u-8-24 {
        margin-right: 0.5rem;
      }

      .security-key-method {
        .select2-container {
          width: 100% !important;
        }
      }
    }
  }
}
