main {
  .electoralprocess-configurations {
    padding-left: 1rem;
    padding-right: 1rem;

    .electoralprocess-configuration-field {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }
}
