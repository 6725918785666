$sprite-path: '../assets/images/icons-sprite.png';
$font-mult: 1 !global;

@import 'external';
@import 'colors';
@import 'utils/module';
@import 'variables';
@import 'resets';
@import 'print';
@import 'components/module';
@import 'layout/module';
@import 'pages/module';

.access-text {
  @import 'access_text';
  @import 'variables';
  @import 'resets';
  @import 'print';
  @import 'components/module';
  @import 'layout/module';
  @import 'pages/module';
}

// Alternative theme
body.access-color {
  $sprite-path: '../assets/images/access-icons-sprite.png';
  $font-mult: 1 !global;
  // Override color variables.
  @import 'access_colors';
  // All imports must be redone inside the selector.
  @import 'variables';
  @import 'resets';
  @import 'print';
  @import 'components/module';
  @import 'layout/module';
  @import 'pages/module';

  // Alternative fonts in this theme
  &.access-text {
    $font-mult: 1.3 !global;
    // Override color variables.
    // All imports must be redone inside the selector.
    @import 'variables';
    @import 'resets';
    @import 'print';
    @import 'components/module';
    @import 'layout/module';
    @import 'pages/module';
    @import 'access_text';
  }
}
