.electoralroll-search-results {
  align-items: center;
  display: flex;
  flex-direction: column;

  font-size: 18px;
  font-weight: 600;

  .text-success {
    color: $ico-success;
  }

  .text-error {
    color: $alert-error;
  }
}
