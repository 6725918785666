// sass-lint:disable no-color-literals

.management {
  // header
  .site-header {
    .site-top .pure-menu > span {
      float: left;
      font-size: 14px;
      height: 75px;
      line-height: 75px;
      margin-left: 62px;
    }

    .site-top .pure-menu .pure-menu-item {
      margin-left: 17px;
    }

    .site-top .pure-menu .pure-button {
      min-width: auto;
      padding-left: 18px;
      padding-right: 18px;
    }

    .site-top .evoto-logo {
      height: 100%;
    }

    .sub-header {
      background: #e4eaf1;

      .pure-menu .pure-menu-list:not(.pull-right) .choose-language {
        height: 60px;
        line-height: 60px;
        width: 210px;
      }

      .pure-menu-link {
        color: #7f96b8;
      }

      .pure-menu-link:hover,
      .pure-menu-link.selected {
        border-color: $evote-default;
        color: $evote-default;
      }

      .pure-menu-horizontal {
        .pure-menu-list {
          display: block;
        }
      }
    }
  }

  main.wrap {
    min-height: calc(100% - 235px);
  }

  .pure-breadcrumbs:not(:empty) + main.wrap {
    min-height: calc(100% - 284px);
  }

  .pure-breadcrumbs:empty + main.wrap {
    min-height: calc(100% - 245px);
  }

  .members-table {
    td {
      padding-left: 10px;
      padding-right: 10px;
      vertical-align: top;
    }

    .management-members-email-field {
      width: 255px;
    }

    .management-members-name-field {
      width: 320px;
    }

    .management-members-role-field {
      width: 150px;
    }
  }
}

.management.election-list {
  .pure-table tr {
    // sass-lint:disable-block class-name-format

    .operations_panel,
    .configs,
    .calendar,
    .categories,
    .electoral_rolls,
    .candidates,
    .bulletin {
      text-align: center;
    }

    td.configs,
    td.calendar,
    td.categories,
    td.electoral_rolls,
    td.candidates,
    td.bulletin {
      color: #888;
      vertical-align: top;

      a {
        text-decoration: none;
      }
    }
  }

  .pure-panel.legend {
    background-color: $white;
    border: 1px solid #e4eaf1;
    font-family: $roboto;
    font-size: 13px;
    font-weight: 300;
    padding: 15px 28px;
    text-align: right;

    div {
      display: inline-block;
      line-height: 24px;
      margin-left: 38px;
      vertical-align: middle;
    }

    div > span {
      display: inline-block;
      vertical-align: middle;
    }

    .ev-icon {
      border-width: 2px;
      height: 24px;
      margin-right: 10px;
      vertical-align: middle;
      width: 24px;
    }
  }
}

.management.elecprocess-detail,
.management.election-detail {
  .pure-sub {
    padding: 0 30px;
  }

  .pure-table {
    .table-opt {
      width: 115px;
    }

    .col-doc {
      padding-left: 0;
      padding-right: 0;
    }

    .col-logo {
      padding: 0;
      width: 84px;

      [data-upload] {
        background-color: #ddd;
        height: 84px;
        position: relative;
        width: 84px;
      }

      [data-upload] i {
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        position: absolute;
        top: 50%;
      }
    }

    & i {
      margin: 0;
    }

    .col-move {
      color: #a7b7cf;
      width: 55px;
    }

    .pure-table {
      th {
        padding-bottom: 0;
        padding-top: 0;
      }

      td {
        padding-bottom: 10px;
        padding-top: 10px;
      }

      .col-doc-designation {
        width: 215px;
      }

      .col-opt {
        padding-left: 0;
        padding-right: 0;
      }

      .col-move {
        padding-right: 0;
        padding-top: 12px;
        width: 40px;
      }
    }
  }

  .pure-field.radioselect {
    label[for='id_bulletin-presentation_mode_0'],
    label[for='id_bulletin-presentation_mode_1'] {
      position: relative;
      right: 0;
    }

    label[for='id_bulletin-presentation_mode_0'] {
      margin-right: 50px;
      padding-right: 35px;
    }

    label[for='id_bulletin-presentation_mode_1'] {
      padding-right: 65px;
    }

    label[for='id_bulletin-presentation_mode_0']::after,
    label[for='id_bulletin-presentation_mode_1']::after {
      background-color: transparent;
      background-image: url($sprite-path);
      background-repeat: no-repeat;
      bottom: -2px;
      content: '';
      display: inline-block;
      position: absolute;
      right: 0;
    }

    label[for='id_bulletin-presentation_mode_0']::after {
      background-position: -150px -540px;
      height: 19px;
      width: 25px;
    }

    label[for='id_bulletin-presentation_mode_1']::after {
      background-position: -150px -511px;
      height: 18px;
      width: 54px;
    }
  }

  .pure-panel {
    label {
      white-space: normal;
    }
  }

  .pure-form #field_id_hondt_total_seats {
    margin-top: 8px;
  }
}

.management.election-manage {
  .manage-count-title {
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 15px;
    text-align: center;
  }

  .manage-count {
    @include border-radius(3px);
    background-color: #eee;
    font-family: $roboto;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 15px 15px 12px;
    text-align: center;
  }

  .table-limited {
    height: 300px;
    overflow-y: auto;
  }

  .manage-scrutiny {
    display: block;
    font-family: $roboto;
    font-weight: 300;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    position: relative;
    width: 50%;

    & > li:not([data-scrutiny-error]) {
      @include opacity(0.6);
      border-bottom: 3px solid $white;
      display: block;
      padding: 10px 20px;
      text-transform: uppercase;
      transition: opacity 400ms linear;
    }

    & > li:last-child {
      border-bottom: 0;
    }

    & > .scrutiny-working {
      @include opacity(1);
    }

    & > li > .scrutiny-text {
      float: right;
      text-transform: none;
    }

    & > .scrutiny-working > .scrutiny-text {
      animation: blinker 1.5s linear infinite;
      font-weight: bold;
    }

    [data-scrutiny-error] {
      display: none;
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      top: 0;
      z-index: 10;
    }

    [data-scrutiny-error] p {
      margin-top: 0;
    }
  }

  @keyframes blinker {
    50% {
      opacity: 0.6;
    }
  }
}

.management.electproccess-summary {
  .pure-sec-body {
    label {
      color: $frm-input-label;
    }
  }
}

.elections-forms hr:first-child {
  display: none;
}

.history-sidebar {
  &.open > .history-info {
    right: 0;
  }

  &.open > .history-bar {
    right: -40px;
  }

  .history-bar {
    @include box-shadow(0, 0, 5px, 1px, rgba(0, 0, 0, 0.25));
    background-color: $tab-bg;
    border-right: 1px solid $white;
    font-family: $roboto;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 200ms linear; // sass-lint:disable-line no-transition-all
    width: 39px;
    z-index: 3;

    .toggle-sidebar {
      display: block;
      margin: 13px 0 53px 3px;

      .ev-icon {
        transform: rotate(180deg);
      }
    }

    .boxnumber {
      background-color: $hd-scnd-color;
      color: $white;
      float: right;
      height: 40px;
      line-height: 40px;
      position: relative;
      width: 39px;
    }

    span {
      color: $hd-scnd-color;
      display: inline-block;
      font-family: $roboto;
      font-weight: 500;
      line-height: 40px;
      margin-top: 250px;
      text-transform: uppercase;
      transform: rotate(-90deg);
      white-space: nowrap;
      width: 40px;
    }
  }

  .history-info {
    @include box-shadow(0, 0, 5px, 1px, rgba(0, 0, 0, 0.25));
    background-color: $white;
    bottom: 0;
    height: 100%;
    position: fixed;
    right: -415px;
    top: 0;
    transition: all 200ms linear; // sass-lint:disable-line no-transition-all
    width: 415px;
    z-index: 2;

    .bar-title {
      border-bottom: 1px solid $th-color;
      color: $hd-scnd-color;
      font-size: 18px;
      height: 75px;
      line-height: 75px;
      padding: 0 25px 0 40px;
      text-transform: uppercase;

      .ev-icon {
        float: right;
        margin-top: 24px;
      }
    }

    .entries {
      height: calc(100% - 75px);
      list-style-type: none;
      overflow-y: auto;
      padding-left: 0;

      .jscroll-loading {
        text-align: center;
      }
    }

    .entry {
      &.new {
        background-color: $history-new-bg;
      }

      .details {
        border-bottom: 1px solid $th-color;
        color: $hd-scnd-color;
        font-size: 14px;
        margin: 0 25px 0 40px;
        padding: 15px 0 22px;

        .date {
          margin-bottom: 10px;
        }

        .name {
          color: $txt-color;
          margin-bottom: 9px;
        }

        .description {
          margin-bottom: 3px;
        }

        .identifier {
          color: $txt-color;
        }
      }
    }
  }
}

.overlay {
  background-color: rgba($dark-bg, 0.5);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  &.active {
    display: block;
    z-index: 2;
  }
}
