//generic colors2
$evote-default: #20283d;
$dark-bg: #2f3242; // rgb(47,50,66)
$alt-dark-bg: #80171b;
$txt-color: #2c323f; // rgb(44, 50, 63)
$white: #fff;
$gray: #808080;
$black: #000;

// Titles
$hd-color: #3f4359; // rgb(63,67,89)
$hd-scnd-color: #7f96b8; // rgb(127,150,184)
$hd-block-bg: #5f6e89;

// Menus
$lightgloss: rgba(255, 255, 255, 0.7);
$footer-link-color: $lightgloss;
$footer-bg-color: $evote-default;

// button colors
$btn-font-bg: $dark-bg;
$btn-color: $white;
$btn-color-medium: #5f6d88;
$btn-bg: #7e95b8;
$btn-border: $btn-bg;
$btn-large-border: $btn-color-medium;

$btn-hover-color: $btn-color;
$btn-hover-bg: #5a78a5;
$btn-hover-border: $btn-hover-bg;

$btn-inverted-color: $btn-bg;
$btn-inverted-bg: $btn-color;
$btn-inverted-border: $btn-bg;

$btn-inactive-color: #bbb;
$btn-inactive-bg: #eee;
$btn-inactive-border: $btn-inactive-color;

// icons
$ico-success: #a3d900;
$ico-noinfo: #aaa;
$ico-inactive: #ddd;

// form colors
$frm-input-border: $btn-border;
$frm-input-color: $dark-bg;
$frm-input-label: $hd-scnd-color;
$frm-input-focus: $btn-hover-border;
$frm-input-error: #ff7373;
$frm-input-success: #a3d900;

// tabs
$tab-acc: #cfdae7;
$tab-bg: #e4eaf1;
$tab-border: $white;
$tab-text: #2c323f;
$tab-inactive-bg: #eee;
$tab-inactive-text: #aaa;
$tab-title-text: #2c323f;

$tab-acc-title: $txt-color;

// alerts
$alert-error: #ff7373;
$alert-dk-error: #ff5959;
$alert-help: #f1f4f8;
$alert-dk-help: $tab-bg;
$alert-info: #75849f;
$alert-dk-info: $hd-block-bg;
$alert-warning: #e8ac3c;
$alert-dk-warning: #dfa941;

// table colors
$th-color: #a7b7cf;
$tbl-border: $th-color;
$tbl-strip: $alert-help;
$tbl-drk-strip: #f6f6f6;

// window
$window-bg: #434655;
$window-color: #5f6d88;

// Admin
$admin-footer-bg: #e4eaf1;
$admin-menu-bg: #e4eaf1;
$admin-sub-menu-bg: $admin-footer-bg; // rgb(127,150,184)
$admin-sub-menu-text: $dark-bg;

// Search
$search-bottom-border: #2f3242;

// History colors
$history-new-bg: #f4f4f4;

// Global alert
$global-alert-bg: $alert-warning;
