.ev-icon,
.fi-icon::before {
  background: transparent url($sprite-path) 0 0 no-repeat;
  display: inline-block;
  height: 30px;
  width: 30px;
}

// right align
.fi-icon::before {
  content: '';
  margin-left: 8px;
  position: absolute;
  right: 2px;
  top: 2px;
}

// left align
.fi-icon-l.fi-icon::before {
  left: 8px;
  margin-left: 0;
  right: auto;
}

.fi-icon,
.ev-icon {
  &.alg-r {
    float: right;
    margin-top: -4px;
  }

  &.alg-l {
    float: left;
    margin-top: -4px;
  }

  &.evi-circle {
    @include border-radius(50%);
    background-color: transparent;
    border: 3px solid $ico-noinfo;
    height: 52px;
    width: 52px;

    &:hover {
      background-color: $ico-noinfo;
    }
  }

  // icons 30px X 30px
  &.fi-edit::before,
  &.evi-edit {
    background-position: 0 0;

    &:hover {
      background-position: -30px 0;
    }
  }

  &.fi-delete::before,
  &.evi-delete {
    background-position: 0 -30px;

    &:hover {
      background-position: -30px -30px;
    }
  }

  &.fi-add::before,
  &.evi-add {
    background-position: 0 -60px;

    &:hover {
      background-position: -30px -60px;
    }
  }

  &.fi-refresh::before,
  &.evi-refresh {
    background-position: 0 -90px;

    &:hover {
      background-position: -30px -90px;
    }
  }

  &.fi-calendar::before,
  &.evi-calendar {
    background-position: 0 -120px;

    &:hover {
      background-position: -30px -120px;
    }
  }

  &.fi-lock-state::before,
  &.evi-lock-state {
    background-position: 0 -150px;

    &:hover {
      background-position: -30px -150px;
    }
  }

  &.fi-avatar::before,
  &.evi-avatar {
    background-position: 0 -180px;

    &:hover {
      background-position: -30px -180px;
    }
  }

  &.fi-email::before,
  &.evi-email {
    background-position: 0 -210px;

    &:hover {
      background-position: -30px -210px;
    }
  }

  &.fi-topic::before,
  &.evi-topic {
    background-position: 0 -240px;

    &:hover {
      background-position: -30px -240px;
    }
  }

  &.fi-msg::before,
  &.evi-msg {
    background-position: 0 -270px;

    &:hover {
      background-position: -30px -270px;
    }
  }

  &.fi-phone::before,
  &.evi-phone {
    background-position: 0 -300px;

    &:hover {
      background-position: -30px -300px;
    }
  }

  &.fi-world::before,
  &.evi-world {
    background-position: 0 -330px;

    &:hover {
      background-position: -30px -330px;
    }
  }

  &.fi-lock::before,
  &.evi-lock {
    background-position: 0 -360px;

    &:hover {
      background-position: -30px -360px;
    }
  }

  &.fi-unread-msg::before,
  &.evi-unread-msg {
    background-position: 0 -390px;

    &:hover {
      background-position: -30px -390px;
    }
  }

  &.evi-unread-msg-darker {
    background-position: -30px -390px;
  }

  &.evi-read-msg-darker {
    background-position: -30px -420px;
  }

  &.fi-read-msg::before,
  &.evi-read-msg {
    background-position: 0 -420px;

    &:hover {
      background-position: -30px -420px;
    }
  }

  &.fi-archive-state::before,
  &.evi-archive-state {
    background-position: -37px -630px;
  }

  &.evi-close {
    background-position: -60px -570px;
  }

  &.evi-success {
    background-position: 0 -450px;
  }

  &.evi-error {
    background-position: -30px -450px;
  }

  &.evi-sel-s-dwn {
    background-position: 0 -480px;
  }

  &.evi-sel-s-up {
    background-position: -30px -480px;
  }

  &.evi-sel-dwn {
    background-position: 0 -510px;
  }

  &.evi-sel-up {
    background-position: -30px -510px;
  }

  &.evi-sel-dis-dwn {
    background-position: 0 -540px;
  }

  &.evi-sel-dis-up {
    background-position: -30px -540px;
  }

  &.evi-sel-drk-dwn {
    background-position: -160px -260px;
  }

  &.evi-sel-drk-up {
    background-position: -130px -260px;
  }

  &.evi-s-arrow-dwn {
    background-position: 0 -570px;
  }

  &.evi-s-arrow-up {
    background-position: -30px -570px;
  }

  &.evi-arrow-left {
    background-position: -130px -290px;
  }

  &.evi-arrow-right {
    background-position: -160px -290px;
  }

  &.evi-reponse {
    background-position: -177px -572px;
  }

  &.evi-delete-msg {
    background-position: -208px -572px;
  }

  &.evi-arrow-med-right {
    background-position: -90px -630px;
  }

  // icons 35px X 35px
  &.evi-doc {
    background-position: -60px 0;
    height: 35px;
    width: 35px;

    &:hover {
      background-position: -95px 0;
    }
  }

  &.evi-xls {
    background-position: -60px -35px;
    height: 35px;
    width: 35px;

    &:hover {
      background-position: -95px -35px;
    }
  }

  &.evi-pdf {
    background-position: -60px -70px;
    height: 35px;
    width: 35px;

    &:hover {
      background-position: -95px -70px;
    }
  }

  &.evi-addrow {
    background-position: -60px -105px;
    height: 35px;
    width: 35px;

    &:hover {
      background-position: -95px -105px;
    }
  }

  &.evi-delrow {
    background-position: -60px -105px;
    height: 35px;
    transform: rotate(45deg);
    width: 35px;

    &:hover {
      background-position: -95px -105px;
    }
  }

  &.evi-open-state {
    background-position: -60px -140px;
    height: 35px;
    width: 35px;

    &:hover {
      background-position: -95px -140px;
    }
  }

  // icons 52px X 52px
  &.evi-new-msg {
    background-position: -130px -156px;
    height: 52px;
    width: 52px;

    &:hover {
      @include opacity(0.7);
    }
  }

  &.evi-arrow-dwn {
    background-position: -130px -104px;
    height: 52px;
    width: 52px;

    &:hover {
      @include opacity(0.7);
    }
  }

  &.evi-arrow-up {
    background-position: -182px -104px;
    height: 52px;
    width: 52px;

    &:hover {
      @include opacity(0.7);
    }
  }

  //  icons 42px X 42px
  &.evi-help {
    background-position: -135px -213px;
    height: 42px;
    width: 42px;
  }

  &.evi-alert {
    background-position: -187px -213px;
    height: 42px;
    width: 42px;
  }

  &.evi-alert-success {
    background-position: -185px -5px;
    height: 42px;
    width: 42px;
  }

  &.evi-info {
    background-position: -182px -156px;
    height: 52px;
    width: 52px;
  }

  // icons 44px X 44px
  &.evi-m-avatar {
    background-position: -70px -185px;
    height: 44px;
    width: 44px;
  }

  &.evi-m-faq {
    // FIXME wrong icon
    background-position: -70px -380px;
    height: 44px;
    width: 44px;

    &:hover {
      @include opacity(1);
    }
  }

  &.evi-m-search {
    @include opacity(0.7);
    background-position: -70px -249px;
    height: 44px;
    width: 44px;

    &:hover {
      @include opacity(1);
    }
  }

  &.evi-m-setup {
    @include opacity(0.7);
    background-position: -70px -315px;
    height: 44px;
    width: 44px;

    &:hover {
      @include opacity(1);
    }
  }

  &.evi-b-help {
    background-position: -60px -435px;
    height: 65px;
    width: 65px;

    &:hover {
      @include opacity(0.7);
    }
  }

  &.evi-b-help-dk {
    background-position: -125px -435px;
    height: 65px;
    width: 65px;

    &:hover {
      @include opacity(0.7);
    }
  }

  &.evi-b-close {
    background-position: -125px -370px;
    height: 65px;
    width: 65px;

    &:hover {
      @include opacity(0.7);
    }
  }

  &.evi-b-search {
    background-position: -60px -500px;
    height: 65px;
    width: 65px;

    &:hover {
      @include opacity(0.7);
    }
  }

  &.evi-b-success {
    background-position: -132px -2px;
    border-color: $ico-success;

    &:hover {
      background-color: $ico-success;
      background-position: -184px -2px;
    }
  }

  &.evi-b-success-small {
    background-position: -134px -319px;
    border-color: $ico-success;

    &:hover {
      background-color: $ico-success;
      background-position: -163px -319px;
    }
  }

  &.evi-b-lock {
    background-position: -132px -56px;
    border-color: $ico-inactive;

    &:hover {
      background-color: transparent;
    }
  }

  &.evi-b-lock-small {
    background-position: -190px -320px;
    border-color: $ico-inactive;

    &:hover {
      background-color: transparent;
    }
  }

  &.evi-noinfo {
    background-image: none;
  }

  &.evi-s-close {
    background-color: $alert-error;
    background-position: -89px -569px;
    border-color: $btn-color;
    height: 35px;
    width: 35px;

    &:hover {
      background-color: $alert-dk-error;
    }
  }
}
