// sass-lint:disable class-name-format

// Inputs: Generic styles
.pure-form input[type='text'],
.pure-form input[type='password'],
.pure-form input[type='email'],
.pure-form input[type='url'],
.pure-form input[type='date'],
.pure-form input[type='month'],
.pure-form input[type='time'],
.pure-form input[type='datetime'],
.pure-form input[type='datetime-local'],
.pure-form input[type='week'],
.pure-form input[type='number'],
.pure-form input[type='search'],
.pure-form input[type='tel'],
.pure-form input[type='color'],
.pure-form select,
.pure-form textarea {
  border-color: $frm-input-border;
  box-shadow: none;
  color: $frm-input-color;
  font-family: $roboto;
  font-size: calc-font-size(15px);
  padding: 8px 11px 6px;
  width: 100%;

  &:focus {
    @include box-shadow(0, 0, 1px, 1px, $frm-input-focus, inset);
    border-color: $frm-input-focus;

    &:invalid {
      // Analize what to do in this cases
      border-color: $frm-input-focus;
      color: $frm-input-color;
    }
  }

  @include placeholder {
    color: $frm-input-label;
    font-style: $roboto;
    font-weight: 300;
  }

  &[disabled] {
    @include placeholder {
      color: $btn-inactive-color;
    }

    background-color: $btn-inactive-bg;
    border-color: $btn-inactive-border;
    color: $btn-inactive-color;
  }
}

.pure-form input[type='submit'].link-button {
  background-color: transparent;
  border: 0;

  &:focus {
    outline: none;
  }
}

// Select / Dropdowns
.select2-container--default {
  font-family: $roboto;
  font-size: calc-font-size(15px);

  span:focus {
    outline: 0;
  }

  .select2-selection--multiple,
  .select2-selection--single {
    border-color: $frm-input-border;
    height: 35px;

    .select2-selection__rendered {
      color: $frm-input-color;
      line-height: 25px;
      margin: 5px 0;
      padding-right: 38px;
    }

    .select2-selection__arrow {
      border-left: 1px solid $frm-input-border;
      height: 25px;
      margin: 5px 0;
      width: 31px;

      b {
        background: transparent url($sprite-path) -7px -490px no-repeat;
        border: 0;
        height: 9px;
        margin: -4px 0 0 -7px;
        width: 15px;
      }
    }
  }

  .select2-selection--multiple {
    height: auto;
    padding-left: 50px;

    .select2-selection__choice {
      margin-top: 4px;
    }

    .select2-selection__rendered {
      margin: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .select2-search__field {
      line-height: 25px;
      margin: 4px 0 0;
      padding: 0;

      &:focus {
        @include box-shadow-none;
      }
    }

    .select2-selection__choice__remove {
      margin-right: 3px;
    }
  }

  &.select2-container--open {
    &.select2-container--below {
      .select2-selection--multiple,
      .select2-selection--single {
        @include border-radius(4px);
      }
    }

    .select2-selection--single {
      .select2-selection__arrow {
        b {
          background-position: -37px -490px;
        }
      }
    }
  }

  .select2-results__options {
    border-color: $frm-input-border;
    margin: 10px 5px 10px 10px;
    padding-right: 5px;

    .select2-results__option {
      border-bottom: 1px solid $frm-input-border;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    // hover
    background-color: $btn-hover-bg;
  }

  .select2-results__option[aria-selected='true'] {
    // selected
    background-color: $btn-bg;
    color: $btn-hover-color;
  }
}

.select2-container--default.select2-container--focus {
  .select2-selection--multiple {
    border: 1px solid $frm-input-border;
  }
}

.select2-container--open {
  .select2-dropdown {
    top: 5px;
  }

  .select2-dropdown--below {
    @include border-radius(4px);
    border: 1px solid $frm-input-border;
  }
}

// Checkbox and radio buttons
.pure-form {
  .pure-radio,
  .pure-checkbox {
    color: $txt-color;

    & > input {
      display: none;
    }

    & > input + span {
      border: 1px solid $frm-input-label;
      cursor: pointer;
      display: inline-block;
      height: 20px;
      position: relative;
      top: 4px;
      transition: background-image 0.5s linear;
      width: 20px;
    }

    & > input:checked + span {
      background: transparent url($sprite-path) -121px -581px no-repeat;
    }

    & > input[disabled] + span {
      @include opacity(0.6);
    }
  }

  .pure-radio {
    color: $frm-input-color;
    cursor: pointer;
    display: inline-block;
    font-size: calc-font-size(15px);
    line-height: 1;
    margin-bottom: 0;
    margin-right: 15px;
    margin-top: 0;

    .pure-radio-input {
      margin-right: 5px;
    }

    & > input {
      & + span {
        @include border-radius(50%);
        border-width: 2px;
        height: 22px;
        top: 6px;
        width: 22px;
      }

      &:checked + span {
        background-position: -141px -581px;
      }
    }
  }

  .input-text-help {
    display: inline-block;
    font-size: calc-font-size(14px);
    margin-top: 6px;
  }

  label.simple-field {
    margin: 0;
  }

  // Field error message based on django messages
  .pure-field.error .errorlist {
    clear: both;
    color: $alert-error;
    font-size: calc-font-size(13px);
    list-style: none;
    margin: 0;
    margin-top: 3px;
    padding: 0;
  }

  .form-add {
    position: relative;
    text-align: center;
    vertical-align: middle;

    &::before {
      background-color: #e4eaf1; // sass-lint:disable-line no-color-literals
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
      z-index: -1;
    }

    .ev-icon {
      z-index: 100;
    }
  }

  .clearablefileinput,
  .fileinput {
    [data-upload] {
      float: right;
      min-width: 160px;
      padding-bottom: 8px;
      padding-top: 8px;
    }

    input[data-upload-name] {
      float: left;
      width: calc(100% - 170px);
    }

    &::after {
      clear: both;
      content: '';
      display: block;
    }

    &.error::after {
      right: 132px;
    }
  }
}

// this errors will be shown with noty
form .errorlist.nonfield {
  display: none;
}

// Form labels
label {
  color: $frm-input-label;
  display: inline-block;
  font-size: calc-font-size(16px);
  line-height: 1;
  margin: 0 0 10px;
  white-space: nowrap;
}

// Field wrapper always to be used surround input tag.
// Necessary to use prefix icons, error styles, etc...
.pure-field {
  margin-bottom: 15px;
  position: relative;

  &.fi-icon {
    &.fi-icon-l input {
      padding-left: 45px;
      padding-right: 11px;
    }

    input {
      padding-right: 50px;
    }

    textarea {
      padding-left: 50px;
    }

    &.textarea::before {
      left: 5px;
      top: 5px;
      z-index: 1;
    }
  }

  &.light-field {
    &::before {
      left: 5px;
      right: auto;
      z-index: 1;
    }

    &.fi-icon {
      input {
        padding-left: 50px;
        padding-right: 0;
      }

      &.multipleajaxselect input {
        padding: 0;
      }

      &.multipleajaxselect::before {
        z-index: 1;
      }
    }

    input {
      @include border-radius(0);
      background-color: transparent;
      border-left: 0;
      border-right: 0;
      border-top: 0;

      &:focus {
        @include box-shadow(0, 1px, 0, 0, $frm-input-focus);
      }
    }
  }

  &.success,
  &.error {
    textarea,
    input {
      padding-right: 30px;

      &:focus {
        color: $frm-input-color;
      }
    }

    .field-wrapper {
      position: relative;

      &::after {
        background: transparent url($sprite-path) -30px -450px no-repeat;
        content: '';
        display: block;
        height: 30px;
        position: absolute;
        right: 2px;
        top: 2px;
        width: 30px;
      }
    }
  }

  &.error {
    textarea,
    input {
      border-color: $frm-input-error;

      &:focus {
        @include box-shadow(0, 0, 1px, 1px, $frm-input-error, inset);
        border-color: $frm-input-error;
      }
    }

    &.light-field {
      input {
        border-color: $frm-input-error;

        &:focus {
          @include box-shadow(0, 1px, 0, 0, $frm-input-error);
          border-color: $frm-input-error;
        }
      }
    }

    .field-wrapper::after {
      background-position: -30px -450px;
    }
  }

  &.success {
    .field-wrapper::after {
      background-position: 0 -450px;
    }
  }

  &.recaptcha,
  &.select {
    &.error,
    &.success {
      .field-wrapper::after {
        display: none;
      }
    }

    &.error {
      .select2-selection {
        border-color: $frm-input-error;
      }
    }
  }
}

[data-upload] {
  cursor: pointer;
  display: inline-block;
}

.datetimeinput input[readonly] {
  background-color: $white;
}
