main {
  .electoralprocess-cancel-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    .electoralprocess-cancel-text {
      margin-bottom: 1rem;
    }
  }
}
