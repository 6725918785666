@media print {
  @page {
    size: A4;
  }

  html,
  body {
    height: 297mm;
    width: 210mm;
  }

  main {
    max-width: 100%;
    width: 100%;
  }

  .site-header,
  .pure-breadcrumbs,
  .site-footer,
  .hide-print {
    display: none;
  }

  .pure-g {
    page-break-inside: avoid;
  }
}
