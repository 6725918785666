.evoteadmin {
  .wrap {
    margin-left: 360px;
    width: calc(100% - 360px);
  }

  main.wrap {
    min-height: calc(100% - 185px);
  }

  .site-header .site-top {
    .pure-menu {
      .pure-menu-logo {
        position: absolute;
        transform: translate(0%, 45%);
        width: 140px;
      }

      .pure-menu-item {
        .pure-menu-link-icon {
          border-left: 0;
        }

        .pure-menu-info {
          font-size: 14px;
          margin-left: 20px;
          margin-right: 20px;
          margin-top: 25px;
        }
      }

      .pure-menu-list {
        .choose-language {
          padding-top: 18px;
          width: 210px;

          // The select2.js selectors are responsible for delegating the correct background and main text color.
          .select2-container--default .select2-selection--single {
            background-color: transparent;
          }

          // sass-lint:disable-block class-name-format
          .select2-container--default .select2-selection--single .select2-selection__rendered {
            color: $white;
          }
        }

        &.login-language-selector {
          float: right;
        }
      }
    }

    .sub-header {
      background: none;
      background-color: $admin-sub-menu-bg;

      .pure-menu-link {
        border-bottom: 4px solid $admin-sub-menu-text;
        color: $admin-sub-menu-text;
        margin: 12px 40px 0 0;
        padding: 6px 0 7px;
      }
    }
  }

  .side-menu {
    background-color: $dark-bg;
    bottom: 0;
    height: 100%;
    left: 0;
    padding-left: 20px;
    position: fixed;
    top: 0;
    width: 340px;

    .logo img {
      margin-bottom: 15px;
      margin-top: 15px;
      width: 235px;
    }

    .sub-item {
      color: $white;
      margin-top: 30px;

      a {
        color: $white;
        text-decoration: none;
      }

      .pure-menu-list .pure-menu-item {
        border-bottom: 1px inset $white;
        margin-right: 20px;

        &:first-child {
          border-top: 1px inset $white;
        }

        &:hover .pure-menu-link,
        &.pure-menu-selected .pure-menu-link {
          background-color: $admin-menu-bg;
          color: $evote-default;
        }

        &.pure-menu-selected {
          border: 0;
        }

        .pure-menu-link {
          color: $white;
          margin-right: -20px;
          padding-bottom: 15px;
          padding-top: 15px;

          &:hover {
            border-bottom: 0;
            border-top: 0;
          }
        }
      }
    }
  }

  .main-content {
    padding-left: 20px;
  }

  .institutionalpage-list tbody tr {
    height: 100px;

    .title span {
      color: $gray;

      a {
        color: $gray;
        text-decoration: none;
      }
    }
  }

  .site-footer {
    background-color: $admin-footer-bg;
    padding-bottom: 1px;
    padding-top: 1px;
  }

  .search-field::before {
    background: url($sprite-path) -30px -600px no-repeat;
  }

  .tinymce-field {
    margin-bottom: 0;
  }

  .mce-panel {
    width: 100% !important; // sass-lint:disable-line no-important
  }

  .pure-button-back {
    margin-right: 15px;
  }

  .sprite-div-success {
    background: url($sprite-path);
    background-position: 0 -450px;
    background-repeat: no-repeat;
    height: 25px;
    width: 25px;
  }

  .sprite-div-error {
    background: url($sprite-path);
    background-position: -30px -450px;
    background-repeat: no-repeat;
    height: 25px;
    width: 25px;
  }

  .certificate-form {
    margin-top: 15px;

    .clearablefileinput {
      margin-top: 25px;
    }
  }
}
