.search {
  .highlight {
    font-family: $roboto;
    font-weight: 500;
  }

  h1 small {
    text-transform: none;
  }

  .search-result {
    font-family: $roboto;
    font-weight: 300;
    padding: 10px 0;

    a {
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}
