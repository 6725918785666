%pure-btn {
  @include border-radius(0);
  border: 1px solid $btn-border;
  display: inline-block;
  font-family: $roboto;
  font-size: calc-font-size(16px);
  line-height: 1;
  min-width: 140px;
  padding: 12px 18px;
  text-align: center;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    background-image: none;
    box-shadow: none;
  }

  &.with-icon {
    line-height: 28px;
    padding: 5px 18px;
  }
}

.pure-button {
  @extend %pure-btn;
}

.pure-button-big {
  @extend %pure-btn;

  font-size: calc-font-size(20px);
  min-width: 230px;
  padding: 18px 18px 16px;
  text-transform: uppercase;
}

.pure-button-small {
  @extend %pure-btn;

  border: 1px solid $btn-border;
  min-width: 100px;
  padding: 8px 10px;
}

// Primary buttons
.pure-button-primary,
a.pure-button-primary {
  background-color: $btn-bg;
  border-color: $btn-border;
  color: $btn-color;

  &.pure-button-left {
    &::after {
      border-right-color: $btn-bg;
    }

    &::before {
      border-right-color: $btn-border;
    }
  }

  &.pure-button-right {
    &::after {
      border-left-color: $btn-bg;
    }

    &::before {
      border-left-color: $btn-border;
    }
  }
}

// Secondary buttons
.pure-button-secondary,
a.pure-button-secondary {
  background-color: $btn-color;
  border-color: $btn-border;
  color: $btn-bg;

  &.pure-button-left {
    &::after {
      border-right-color: $btn-color;
    }

    &::before {
      border-right-color: $btn-border;
    }
  }

  &.pure-button-right {
    &::after {
      border-left-color: $btn-color;
    }

    &::before {
      border-left-color: $btn-border;
    }
  }
}

.pure-button-link {
  background-color: transparent;
  border-color: transparent;
  color: $hd-scnd-color;
  font-weight: 300;
  text-decoration: underline;

  &:hover {
    border-color: transparent;
    color: darken($hd-scnd-color, 10%);
    transition: color 200ms linear;
  }
}

.pure-button-primary,
a.pure-button-primary,
.pure-button-secondary,
a.pure-button-secondary {
  &:hover {
    background: $btn-hover-bg;
    border-color: $btn-hover-border;
    color: $btn-hover-color;
    transition: all 200ms linear; // sass-lint:disable-line no-transition-all

    &.pure-button-left::after,
    &.pure-button-left::before {
      border-right-color: $btn-hover-bg;
      transition: all 200ms linear; // sass-lint:disable-line no-transition-all
    }

    &.pure-button-right::after,
    &.pure-button-right::before {
      border-left-color: $btn-hover-bg;
      transition: all 200ms linear; // sass-lint:disable-line no-transition-all
    }
  }
}

// Disable buttons
.pure-button-disabled,
a.pure-button-disabled,
.pure-button[data-loading] {
  @include opacity(1);
  background: $btn-inactive-bg;
  border-color: $btn-inactive-border;
  color: $btn-inactive-color;

  &:focus,
  &:hover {
    @include opacity(1);
    background: $btn-inactive-bg;
    border: 1px solid $btn-inactive-border;
    color: $btn-inactive-color;
  }

  &.pure-button-left {
    &::after {
      border-right-color: $btn-inactive-bg;
    }

    &::before {
      border-right-color: $btn-inactive-border;
    }
  }

  &.pure-button-right {
    &::after {
      border-left-color: $btn-inactive-bg;
    }

    &::before {
      border-left-color: $btn-inactive-border;
    }
  }

  i {
    @include opacity(0.5);
  }
}

// Rounded buttons
.pure-button-round {
  @include border-radius(30px);
}

.pure-button-smooth {
  @include border-radius(4px);
}

// Large buttons
.pure-button-large,
a.pure-button-large {
  background-color: transparent;
  border: 5px solid $btn-color;
  color: $btn-color;
  min-width: 240px;
  padding: 25px 5px 26px;

  &:hover {
    background-color: $btn-hover-bg;
    transition: all 200ms linear; // sass-lint:disable-line no-transition-all
  }
}

.pure-button-medium {
  background-color: transparent;
  border: 5px solid $btn-large-border;
  color: $btn-color-medium;
  font-family: $roboto;
  font-size: 15px;
  font-weight: 500;
  min-width: 188px;
  padding: 20px 33px;
  text-transform: uppercase;

  &:hover {
    background-color: $btn-large-border;
    color: $white;
    transition: all 200ms linear; // sass-lint:disable-line no-transition-all
  }
}

// Buttons width directions (like arrows) - Left and Right
.pure-button-left {
  border-left: 0;
  margin-left: 24px;
  position: relative;

  &::before,
  &::after {
    border: solid transparent;
    content: ' ';
    height: 0;
    position: absolute;
    right: 100%;
    top: 50%;
    width: 0;
  }

  &::after {
    border-width: 20px;
    margin-top: -20px;
  }

  &::before {
    border-width: 21px;
    margin-top: -21px;
  }
}

.pure-button-right {
  border-right: 0;
  margin-right: 24px;
  position: relative;

  &::before,
  &::after {
    border: solid transparent;
    content: ' ';
    height: 0;
    left: 100%;
    position: absolute;
    top: 50%;
    width: 0;
  }

  &::after {
    border-width: 20px;
    margin-top: -20px;
  }

  &::before {
    border-width: 21px;
    margin-top: -21px;
  }
}
