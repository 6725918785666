// sass-lint:disable-block class-name-format
.view-account_login,
.view-account_reset_password,
.view-account_reset_password_done,
.view-account_reset_password_from_key,
.view-account_reset_password_from_key_done,
.view-account_change_password {
  main.wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h1 {
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: center;
  }

  p {
    font-weight: 300;
    text-align: center;
  }

  .login-box,
  .ama-box {
    padding: 15px;
    width: 100%;
  }

  .pure-button {
    width: 100%;
  }

  // Mobile
  @include media-breakpoint-up(sm) {
    .login-box,
    .ama-box {
      margin: 0 auto;
      width: 80%;
    }
  }

  // Tablet
  @include media-breakpoint-up(md) {
    main.wrap {
      flex-direction: row;
    }

    .login-box,
    .ama-box {
      width: 50%;
    }
  }

  // Wide Tablet
  @include media-breakpoint-up(lg) {
    .login-box,
    .ama-box {
      width: 35%;
    }
  }
}
