.action-bar {
  background-color: $hd-block-bg;
  height: 45px;
  margin-bottom: 9px;
  padding: 6px 20px;

  .pure-button-small {
    background-color: transparent;
    border: 1px solid $btn-color;
    line-height: 32px;
    padding: 0 14px;
    text-align: left;

    &:hover {
      border-color: $btn-color;
    }

    .evi-new-msg {
      background-position: -141px -171px;
      height: 20px;
      margin-left: 5px;
      vertical-align: text-top;
      width: 26px;
    }
  }
}

.message {
  border: 1px solid $frm-input-border;
  margin-bottom: 8px;
  padding: 18px 50px 15px 20px;
  position: relative;

  .message-subject {
    margin-top: 5px;
  }

  .message-subject a {
    color: $txt-color;
    text-decoration: none;
  }

  .message-subject a:hover {
    text-decoration: underline;
  }

  .message-body {
    font-family: $roboto;
    font-weight: 300;
  }

  .message-actions {
    position: absolute;
    right: 45px;
    top: 55px;
  }

  .message-action {
    display: inline-block;
    line-height: 1;
    padding: 5px;
  }

  .message-archive {
    background: transparent;
    border: 0;
    padding: 0;
  }

  hr {
    margin-top: 10px;
  }
}

.message-list {
  .message {
    .message-title {
      white-space: normal;
    }
  }

  .message-actions {
    @include opacity(0);
  }

  .message:hover {
    background-color: $alert-help;
    transition: background-color 300ms linear;

    .message-actions {
      @include opacity(1);
      transition: opacity 300ms linear;
    }
  }
}

.message-form .action-bar {
  margin-bottom: 40px;
}
