.homepage {
  background: transparent url('../assets/images/hp-bg.jpg') center top repeat-x;

  .site-header {
    height: 100px;
    margin-bottom: 0;

    .site-top {
      height: 100%;
    }

    .pure-menu {
      nav {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

      .selected-menu {
        top: -12px;
      }

      .pure-menu-heading img {
        height: 98px;
        width: 230px;
      }

      .pure-menu-link-icon {
        margin-top: 10px;
      }

      .pure-button {
        padding-bottom: 9px;
        padding-top: 9px;
      }

      .profile-info {
        line-height: 60px;
      }
    }

    .pure-menu-children {
      top: 100px;
    }
  }

  .globalalert {
    margin-top: 0;
  }

  .globalalert + main {
    min-height: 611px;
  }

  main {
    color: $btn-color;
    margin-bottom: 0;
    min-height: 676px;

    .brand-logo {
      color: $btn-color;
      margin-top: 90px;
      text-decoration: none;

      p {
        margin: 7px 0 0;
      }
    }

    .description {
      font-size: calc-font-size(16px);
      line-height: 2.3em;
      margin: 77px 55px 0 0;
    }

    .sub-header {
      background-image: none !important; // sass-lint:disable-line no-important
      height: auto;
      margin-top: 81px;

      .pure-menu-link {
        font-size: calc-font-size(17px);
        margin: 27px;
      }
    }

    .homepage-buttons {
      display: flex;
      justify-content: center;

      .large-buttons {
        margin-top: 144px;

        .first-button {
          margin-right: 70px;
        }
      }
    }
  }

  // Tablet
  @include media-breakpoint-between(md, xl) {
    background: none;

    .wrap {
      margin-left: 0;
      margin-right: 0;
      max-width: 1280px;
      padding-left: 15px;
      padding-right: 15px;
    }

    main {
      background: transparent url('../assets/images/hp-bg.jpg') center top repeat-x;
      background-size: cover;

      .brand-logo p {
        margin-top: 50px;
      }

      .description {
        margin: 0;
        padding: 26px;
        text-align: center;
      }

      .reset-margins {
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;

        .pull-left,
        .pull-right {
          float: none;
        }

        .large-buttons {
          align-items: center;
          column-gap: 20px;
          display: grid;
          grid-template-columns: auto auto;
          justify-content: center;
          margin-top: 90px;

          .first-button {
            margin: 0;
          }
        }
      }
    }
  }

  // Mobile
  @include media-breakpoint-between(xs, md) {
    background: none;

    .site-header {
      height: 75px;

      .pure-menu {
        .pure-menu-heading {
          height: 100%;
          padding: 5px;
        }

        .pure-menu-heading img {
          height: 100%;
          width: 190px;
        }

        .mobile-menu img {
          width: 40px;
        }
      }

      nav.pull-right {
        display: none;
        float: none;
        position: initial;
        top: 0;
        transform: none;
      }
    }

    main {
      background: transparent url('../assets/images/hp-bg.jpg') center top repeat-x;

      .reset-margins {
        margin-left: 0;
        margin-right: 0;
      }

      .description {
        margin: 0;
        padding: 26px;
        text-align: center;
      }

      .brand-logo {
        float: none;
        margin-top: 0;
        text-align: center;

        p {
          margin-top: 50px;
        }
      }

      .pure-menu-horizontal {
        display: none;
      }

      .large-buttons {
        align-items: center;
        column-gap: 20px;
        display: grid;
        justify-content: center;
        margin-top: 0;
        padding: 50px;

        .pull-right {
          float: none;
        }

        .first-button {
          margin-right: 0;
        }
      }
    }
  }
}

.access-text.homepage .site-header .pure-menu .selected-menu {
  top: -10px;
}
