.election-search-vote {
  .reference-list-title {
    border-bottom: 1px solid $th-color;
    border-top: 1px solid $th-color;
    color: $hd-scnd-color;
    line-height: 61px;
    padding: 0 60px;
  }

  .reference-list {
    list-style: none;
    padding: 0;

    li {
      color: $hd-block-bg;
      line-height: 67px;
      padding: 0 60px;

      &:nth-child(odd) {
        background-color: $tbl-drk-strip;
      }
    }
  }
}
