.ui-widget {
  font-family: $roboto;
  font-size: calc-font-size(14px);

  .ui-timepicker-div {
    padding-top: 5px;

    dl dd {
      margin-bottom: 7px;
      padding-top: 5px;
    }
  }
}

.ui-sortable-handle {
  cursor: move;
}

.ui-sortable-helper {
  @include box-shadow(0, 0, 5px, 1px, rgba(0, 0, 0, 0.25));
}
