// sass-lint:disable no-vendor-prefixes

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  color: $txt-color;
  font-family: $roboto;
  font-size: calc-font-size(16px);
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $roboto;
  font-weight: 400;
  line-height: 1.2;
}

h1 {
  color: $hd-color;
  font-size: calc-font-size(18px);
  margin-bottom: 8px;
  text-transform: uppercase;
}

h2 {
  color: $hd-color;
  font-size: calc-font-size(18px);
  margin: 0 0 20px;
}

h3 {
  color: $hd-scnd-color;
  font-size: calc-font-size(18px);
}

a {
  color: $hd-scnd-color;
  font-family: $roboto;

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    color: darken($hd-scnd-color, 10%);
    transition: color 200ms linear;
  }
}

dd,
dl,
ul {
  margin: 0;
}

dd {
  font-family: $roboto;
  font-weight: 300;
  margin-bottom: 20px;
}

main {
  margin-bottom: 25px;
  min-height: calc(100% - 278px); // 352px ~= height header + height breadcrumb + height footer + margin-bottom
}

.pure-breadcrumbs:not(:empty) + main.wrap {
  min-height: calc(100% - 322px);
}

.pure-breadcrumbs:empty + main.wrap {
  min-height: calc(100% - 283px);
}

time {
  font-family: $roboto;
  font-weight: 300;
}

hr {
  background-color: #e4eaf1; // sass-lint:disable-line no-color-literals
  border: 0;
  height: 1px;
  margin: 0 0 15px;
  width: 100%;
}

// Custom Scrollbars in WebKit
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  @include border-radius(10px);
  background-color: $tab-bg;
  border: 1px solid $white;
}

.pure-g {
  [class*='pure-u'] {
    font-family: $roboto;
  }

  &.extra-bottom {
    margin-bottom: 10px;
  }

  &.pure-gutter {
    margin-left: -12px;
    margin-right: -12px;

    & > div[class^='pure-u'] {
      box-sizing: border-box;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
