.howitworks {
  background-color: #e4eaf1; // sass-lint:disable-line no-color-literals

  main.wrap {
    margin-bottom: 5px !important; // sass-lint:disable-line no-important

    .title {
      margin-top: 30px;
    }

    .pure-g {
      margin-top: 30px;
    }

    .pure-description {
      margin-bottom: 25px;
    }

    .info-containers {
      padding-left: 135px;

      .info-container {
        margin-bottom: 20px;
      }

      .info-container .info-content .info-title {
        margin: 0;
        text-decoration: uppercase;

        h1 {
          font-weight: bold;
          margin: 0;
          margin-bottom: 5px;
        }
      }

      .info-container.info-button {
        margin-bottom: 43px;
        margin-top: 0;
      }
    }

    .left-pic img {
      position: absolute;
      z-index: -999;
    }
  }

  // Mobile
  @include media-breakpoint-between(xs, md) {
    main.wrap {
      .title {
        text-align: center;
      }

      .howitworks-grid {
        .left-pic {
          align-items: center;
          display: flex;
          justify-content: center;

          img {
            position: initial;
            z-index: 0;
          }
        }

        .info-containers {
          padding: 20px;
          padding-left: 30px;
        }
      }
    }
  }
}
