// Stylesheet: Generic styles for pages

h2.pure-block-title {
  background-color: $hd-block-bg;
  color: $btn-color;
  margin: 0;
  padding: 12px 20px;

  .fi-icon {
    display: inline-block;
    margin-left: 50px;
    position: relative;

    &::before {
      left: -40px;
      margin-left: 0;
      top: -5px;
    }
  }
}

.pure-panel {
  background-color: $alert-help;
  margin-bottom: 20px;
  padding: 40px 30px;

  dd {
    font-family: $roboto;
    font-weight: 300;
    margin-bottom: 20px;
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > .pure-g:last-child .pure-field,
  & > .pure-field:last-child {
    margin-bottom: 0;
  }
}

.pure-title-row {
  margin-bottom: 20px;

  h1 {
    margin-top: 15px;

    &.light-blue {
      color: $frm-input-label;
    }
  }
}

.pure-sec-block {
  .pure-sec-title {
    background-color: $alert-help;
    color: $tab-acc-title;
    font-size: calc-font-size(16px);
    font-weight: normal;
    height: 40px;
    line-height: 40px;
    margin: 0;
    padding: 0 20px;
  }

  .pure-sec-body {
    padding: 20px;

    .pure-description {
      background-color: $tbl-drk-strip;
      font-family: $roboto;
      font-weight: 300;
      margin: -20px -20px 0;
      padding: 30px 50px;
    }

    .small-text {
      font-size: calc-font-size(15px);

      label {
        font-size: calc-font-size(15px);
      }
    }

    label {
      color: $txt-color;
      line-height: 25px;
      margin-bottom: 0;

      &.small-label {
        color: $frm-input-label;
        font-weight: normal;
        margin-left: 20px;
        margin-right: 5px;

        &.simple {
          margin-left: 0;
        }
      }

      &.main-label {
        font-family: $roboto;
        font-weight: 500;
      }
    }

    .pure-sec-row {
      border-bottom: 1px solid $tab-bg;
      padding: 14px 0;

      &:last-child {
        border: 0;
      }

      &.with-button {
        border-bottom: 0;
        padding: 8px 0;

        label {
          line-height: 42px; // The button size
        }
      }
    }

    .pure-sec-dk-row {
      background-color: $tbl-drk-strip;
      margin: 6px 0;
      padding: 14px 0;

      &:last-child {
        margin-bottom: 0;
      }

      label {
        color: $txt-color;
        font-weight: bold;
      }

      &.with-button {
        padding: 5px 0;

        .vertical-align {
          line-height: 42px;
        }
      }
    }

    &.full-row {
      padding: 0;

      .pure-description {
        margin: 0;
      }

      .pure-sec-dk-row,
      .pure-sec-row {
        padding: 14px 50px;

        &.with-button {
          padding: 8px 12px 8px 50px;
        }

        &.pure-sec-tab {
          padding-left: 75px;
        }
      }

      &.vertical-space {
        padding-bottom: 20px;
        padding-top: 20px;
      }

      &.horizontal-space {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    & > .pure-g:last-child .pure-field {
      margin-bottom: 0;
    }
  }
}

.pure-sec-accordion {
  .pure-sec-block {
    margin-bottom: 8px;

    .pure-sec-title {
      background-color: $tab-acc;
      cursor: pointer;
      margin: 0;
      padding: 0 12px 0 20px;
    }

    .pure-sec-title i {
      float: right;
      margin-top: 5px;
    }

    .pure-sec-body {
      display: none;
    }
  }
}

.ev-top-actions {
  .pure-form {
    margin-top: 4px;

    .pure-g div {
      width: auto;
    }

    select {
      min-width: 95px;

      &[name$='date'] {
        min-width: 125px;
      }
    }
  }

  &.separate {
    .pure-button:not(:last-child) {
      margin-left: 20px;
    }
  }
}

// pure window styles
.access-color.pure-window .pure-window-logo img {
  @include filter(1);
}

.pure-window {
  background-color: $window-bg;
  height: auto;
  margin-top: 55px;

  main {
    background-color: $btn-color;
    min-height: 700px;
    position: relative;
  }

  .pure-window-header {
    margin: 25px 13px 33px 40px;
    overflow: hidden;

    .pure-window-logo {
      margin-top: 25px;

      img {
        margin-right: 34px;
        width: 100px;
      }

      time {
        color: $window-color;
        font-size: calc-font-size(15px);
        position: relative;
        text-transform: lowercase;
        top: -6px;
      }
    }

    .pure-window-help-btn {
      background-color: $alert-dk-info;
      display: inline-block;
      height: 60px;
      margin-left: 220px;
      width: 65px;

      &.selected {
        background-color: $alert-dk-help;

        i {
          background-position: -125px -437px;
        }
      }

      i {
        @include opacity(0.6);

        background-position: -60px -437px;
        height: 60px;
      }
    }

    .pure-window-close {
      @include border-radius(50%);
      border: 1px solid $dark-bg;
      display: inline-block;
      height: 110px;
      margin-top: -55px;
      text-align: center;
      text-decoration: none;
      width: 110px;

      div {
        margin-top: 65px;
      }
    }
  }

  // tabs menu is smaller
  .pure-menu-tab {
    height: 40px;

    .pure-menu-tab-title {
      color: $txt-color;
      padding: 0 0 0 20px;
    }

    .pure-menu-item {
      line-height: 38px;
    }
  }

  .pure-menu-tab-title.top-offset {
    text-transform: none;
  }

  .pure-menu-content {
    height: 35px;
    min-height: 35px;

    h2 {
      font-size: calc-font-size(17px);
      line-height: 35px;
    }
  }

  .pure-window-description {
    margin: 12px 20px 30px;
  }

  .pure-window-body {
    padding: 0 30px 30px;
  }

  .pure-window-help-page {
    background-color: $alert-dk-help;
    bottom: 30px;
    display: none;
    left: 30px;
    padding: 40px;
    position: absolute;
    right: 30px;
    top: 60px;
    z-index: 5;

    .help-text-wrapper {
      bottom: 30px;
      left: 30px;
      overflow-y: scroll;
      padding-right: 20px;
      position: absolute;
      right: 30px;
      top: 60px;
    }

    h2 {
      font-size: calc-font-size(20px);
      line-height: 1;
      margin-bottom: 40px;
    }

    h3 {
      color: $txt-color;
      font-family: $roboto;
      font-size: calc-font-size(16px);
      font-weight: 500;
      margin-bottom: 5px;
    }

    p {
      font-size: calc-font-size(14px);
      margin: 0 0 20px;
    }

    ul {
      font-size: calc-font-size(14px);
      margin-bottom: 10px;
    }

    .pure-window-help-close {
      @include border-radius(50%);
      background-color: $alert-dk-help;
      display: inline-block;
      height: 68px;
      position: absolute;
      right: 0;
      top: -34px;
      width: 68px;

      i {
        margin: 0 0 0 2px;
      }
    }
  }
}

.electoral-calendar-form label {
  font-size: calc-font-size(14px);
}

.addrow {
  border-bottom: 1px solid $tab-bg;
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
  text-align: center;

  .evi-addrow {
    cursor: pointer;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -18px;
  }
}

.confirm-msg {
  padding-bottom: 180px;
  padding-top: 38px;
}

.general-photo {
  margin-top: 15px;

  img {
    @include border-radius(50%);
    display: inline-block;
    height: 90px;
    overflow: hidden;
    width: 90px;
  }
}

.personal-data {
  .pure-g {
    @include border-radius(4px);
    border: 1px solid $frm-input-border;
    margin-bottom: 5px;
    padding: 15px 35px;

    .pure-field {
      margin-bottom: 0;
    }
  }
}

.gov-auth {
  img {
    float: left;
    margin: 20px 30px 0 40px;
  }

  .pure-button {
    margin-top: 22px;
  }
}

.search-form {
  .pure-form {
    background-color: $tbl-drk-strip;
    margin-bottom: 116px;
    margin-top: 12px;

    input {
      font-size: calc-font-size(17px);
      padding-bottom: 7px;
      padding-top: 8px;
    }
  }

  .pure-success {
    margin-bottom: 38px;
    margin-left: 60px;
    margin-top: -80px;
    max-width: 300px;

    &::after {
      // sass-lint:disable-block property-sort-order
      border: solid transparent;
      border-color: rgba($alert-info, 0); // sass-lint:disable-line no-color-literals
      border-bottom-color: $alert-info;
      border-width: 20px;
      bottom: 100%;
      content: ' ';
      height: 0;
      left: 50px;
      pointer-events: none;
      position: absolute;
      width: 0;
    }
  }

  .search-row {
    padding-left: 60px;
    padding-top: 22px;
  }

  .search-button {
    font-size: calc-font-size(17px);
    height: 83px;
    line-height: 46px;
    padding-left: 9px;
    padding-right: 38px;
    text-transform: uppercase;

    &::before {
      background: transparent url($sprite-path) -70px -249px no-repeat;
      content: '';
      float: left;
      height: 44px;
      padding-right: 10px;
      width: 44px;
    }
  }
}

.cc-banner.cc-bottom {
  box-shadow: 0 1px 8px $black;
}

.pure-cookie-msg {
  font-family: $roboto;
  font-weight: 300;
}

// Error pages styles
.error-page {
  h1 {
    font-size: 100px;
  }
}

.pure-disabled {
  @include opacity(0.6);
}

// Mobile
@include media-breakpoint-between(xs, md) {
  .voters .wrap {
    .pages-grid {
      h1 {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
      }
    }

    p {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
