.wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: $content-max-with;
}

.site-header {
  height: 135px;
  margin-bottom: 15px;

  &.sticky {
    @include box-shadow(0, 2px, 10px, 0, rgba(74, 74, 74, 0.75));
    position: fixed;
    width: 100%;
    z-index: 4;
  }

  a {
    color: $btn-color;
  }

  .site-top {
    background-color: $evote-default;
    color: $white;
    height: 75px;
  }

  .pure-menu {
    height: 100%;

    .mobile-menu {
      display: none;
    }

    .pure-menu-heading {
      padding: 0;

      img {
        height: 75px;
        width: 182px;
      }
    }

    .pure-menu-item {
      float: left;
    }

    .pure-menu-link-icon {
      border-left: 1px solid $lightgloss;
      color: $lightgloss;
      display: inline-block;
      font-size: calc-font-size(14px);
      height: 44px;
      line-height: 44px;
      margin: 15px 0 0;
      min-width: 44px;
      padding: 0 7px;
      text-align: center;
      text-decoration: none;

      &.link-button {
        border-left: 1px solid $lightgloss;
        text-transform: uppercase;

        &:last-child {
          border-right: 1px solid $lightgloss;
          margin-right: 35px;
        }
      }

      &:hover {
        color: $btn-color;
        transition: color 200ms linear;
      }

      &.user {
        color: $btn-color;
      }
    }

    .selected-menu {
      border-color: transparent transparent $btn-color;
      border-style: solid;
      border-width: 0 22px 22px;
      height: 0;
      position: relative;
      top: -16px;
      width: 0;
    }

    .pure-button {
      border: 0;
      font-size: calc-font-size(16px);
      line-height: 44px;
      padding: 15px 33px 16px 9px;

      &.logout-button {
        margin-left: 20px;
        min-width: auto;
        padding: 15px 18px 16px;
      }

      .ev-icon {
        border-right: 1px solid rgba(255, 255, 255, 0.7); // sass-lint:disable-line no-color-literals
        float: left;
        margin-right: 20px;
        padding-right: 10px;
      }
    }

    .profile-info {
      display: inline-block;
      font-family: $roboto;
      font-size: calc-font-size(15px);
      font-weight: 300;
      line-height: 75px;
      padding-left: 61px;
      position: relative;
    }
  }

  .pure-menu-children {
    @include box-shadow(0, 4px, 4px, 0, rgba(0, 0, 0, 0.75));
    left: 0;
    list-style: none;
    overflow: hidden;
    padding: 20px 0;
    top: 75px;
    width: 100%;

    li {
      .close-menu {
        @include border-radius(50%);
        border: 1px solid $dark-bg;
        height: 110px;
        position: absolute;
        right: 0;
        top: -90px;
        width: 110px;
      }

      .close-menu i {
        margin-left: 40px;
        margin-top: 70px;
      }

      .menu-wrapper {
        max-width: 846px;
        overflow: hidden;

        &.menu-search {
          margin: 20px auto 36px;
        }

        &.menu-settings {
          margin: 13px auto 17px;
          padding-left: 45px;
        }
      }

      // search dropdown
      input {
        float: left;

        &[type='text'] {
          font-size: calc-font-size(26px);
          margin-left: 40px;
          padding-bottom: 12px;
          width: 710px;
        }

        &[type='submit'] {
          background: transparent url($sprite-path) -60px -500px no-repeat;
          border: 0;
          font-size: 0;
          height: 65px;
          margin-left: 20px;
          margin-top: -5px;
          width: 65px;

          &:active,
          &:focus {
            outline: none;
          }
        }
      }

      // settings dropdown
      .settings-option {
        float: left;
        margin-right: 50px;
      }

      .settings-title {
        font-size: calc-font-size(21px);
        margin-bottom: 12px;
      }

      .box-font,
      .box-color {
        float: left;
        line-height: 50px;
        margin: 15px 22px 0 0;
        text-align: center;
      }

      .box-font {
        border: 1px solid $dark-bg;
        min-width: 176px;
        padding: 14px;

        &.box-large {
          font-size: calc-font-size(20px);
        }

        &.selected {
          border: 2px solid $dark-bg;

          div {
            background-color: $btn-font-bg;
            color: $btn-color;
            display: inline-block;
            min-width: 176px;
          }
        }
      }

      .box-color {
        min-width: 117px;

        &.selected {
          border: 2px solid $dark-bg;
          min-width: 99px;
          padding: 14px 7px;

          div {
            height: 48px;
          }
        }

        &.box-color1 {
          div {
            background-color: $dark-bg;
          }
        }

        &.box-color2 {
          div {
            background-color: $alt-dark-bg;
          }
        }

        div {
          height: 80px;
        }
      }
    }
  }
}

.sub-header {
  background: transparent url('../assets/images/sub-header-bg.png') center center repeat-x;
  background-size: cover;
  height: 60px;

  .pure-menu-item:last-child .pure-menu-link {
    margin-right: 0;
  }

  .pure-menu-link {
    border-bottom: 4px solid transparent;
    color: $btn-color;
    font-family: $roboto;
    font-size: calc-font-size(16px);
    font-weight: 300;
    line-height: 1;
    margin: 12px 40px 0;
    padding: 10px 0 7px;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background: none;
    }

    &.selected,
    &:hover {
      border-color: $btn-color;
      transition: color 200ms linear;
    }
  }
}

// Tablet
@include media-breakpoint-between(md, xl) {
  .wrap {
    margin-left: 0;
    margin-right: 0;
    max-width: 1280px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

// Mobile
@include media-breakpoint-between(xs, md) {
  .site-header {
    height: min-content;

    .mobile-header {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .mobile-header-image {
        height: 100%;
        padding: 5px;
      }

      .mobile-header-image img {
        height: 100%;
        width: 190px;
      }

      .mobile-menu {
        align-items: right;
        cursor: pointer;
        display: flex;
        justify-content: end;
        padding: 5px;
        width: min-content;

        img {
          width: 40px;
        }
      }
    }

    nav.mobile-header-menu {
      display: none;
      float: none;
      position: relative;
      top: 0;
      transform: none;
      width: 100%;

      .mobile-header-menu-list {
        align-items: center;
        background-color: $evote-default;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        float: none;
        justify-content: center;
        padding: 15px;

        .mobile-header-menu-item:first-child {
          margin-left: 15px;
        }

        input,
        .link-button:last-child {
          margin: 0;
          margin-right: 15px;
          margin-top: 8px;
        }

        .pure-button-primary:last-child {
          margin: 15px;
        }

        .mobile-header-menu-item:last-child {
          align-items: center;
          display: flex;
          flex-basis: 100%;
          justify-content: center;
        }
      }
    }

    .sub-header {
      display: flex;
      height: max-content;

      .sub-header-menu .sub-header-menu-list {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        position: initial;
        width: 100%;

        .sub-header-menu-item {
          position: initial;
        }

        .sub-header-menu-item a {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}
