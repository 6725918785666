// Pagination
.pure-pag ul {
  display: inline-block;
  margin-bottom: 15px;
  margin-left: 0;
  padding: 0;

  & > li {
    color: $btn-hover-bg;
    display: inline;
    float: left;
    line-height: 30px;
    margin: 0 3px;

    & > a {
      border: 1px solid $btn-hover-bg;
      color: $btn-hover-bg;
      float: left;
      height: 30px;
      line-height: 30px;
      padding: 0 10px 0 9px;
      text-decoration: none;
    }

    a.selected,
    a:hover {
      background-color: $btn-hover-bg;
      color: $btn-color;
    }

    a.disabled {
      @include opacity(0.6);
      background-color: $btn-inactive-bg;
      border-color: $btn-inactive-color;
      cursor: not-allowed;
    }

    &:first-child > a,
    &:last-child > a {
      height: 30px;
      width: 30px;
    }

    &:first-child > a {
      background-image: url($sprite-path);
      background-position: -130px -290px;
      background-repeat: no-repeat;
      border-left-width: 1px;
      color: $btn-hover-bg;

      &:hover {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AsQDx4JW5gX6gAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAABQSURBVEjH7dUxDcAwDAXRQCiEQjDEQgqDBkKZFMJ1yJA9Q2JV9wicPHy5FElZAAE0IFZHX7prR/QBDqNGZ8M3w7lrOusuNm4cqP//TpJS+AChrfOqTQkKRQAAAABJRU5ErkJggg==');
        background-position: 0 0;
      }

      &.disabled {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AsQDQExvUR1hAAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAACbSURBVEjH7dWxFYMwDEXRF3rWYAAmYBsadTAAA0CnhgmyTQbwGlmAxmlyUiAQuIh+L99jW5YhEok45XF2AVVtgBGYRSTtrasc0BVogc5SWzmgNZCA5+VH/QPtReR9KeyBmmEv1AR7otbmGjIKMJ5BrfACfLBZVevbBkiROy7a1UXfsRd+eGTmD6HPDdcA0y2z+gt/5d1HIpE/ygY/x0sWElmZiAAAAABJRU5ErkJggg==');
        background-position: 0 0;
      }
    }

    &:last-child > a {
      background-image: url($sprite-path);
      background-position: -160px -290px;
      background-repeat: no-repeat;

      &:hover {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AsQDBsMVMOpeQAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAABQSURBVEjH7dVBDYBADEXBlYAEJKxEJOEAJOAECcOBAw66ZPPHwEuTNm0tIkZAx4leHd697tI4FlyJJ14VX32OeSdOdM4txjbqdMZ8p4j4pQfO8fOq48plpwAAAABJRU5ErkJggg==');
        background-position: 0 0;
      }

      &.disabled {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AsQDxcxolgUPQAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAACeSURBVEjH7ZMxEcMwDEWfsodGAJRNEWTRlgIogHTTEjZFUAChYQJZPHax5DZ3Ob3Nd/I9Wf6CJEk6IS3FZjYBD2BV1T0iHhrrZ+AGbLWJv4mfwA6MUbm0XjCzEdiACSjA7Bm7eLrtIRfvqKJyiQQkIh8iYlUtdb2ogVt+lepvL17rsQCva/7xKak+ZY97ST3huveQesRv4BOVJklyDQ4Dx0sWPRyDlAAAAABJRU5ErkJggg==');
        background-position: 0 0;
      }
    }
  }
}

// Tabs
.pure-menu-tab {
  height: 45px;

  .pure-menu-tab-title {
    float: left;
    font-family: $roboto;
    font-size: calc-font-size(18px);
    font-weight: 500;
    margin: 0;
    max-width: 500px;
    overflow: hidden;
    padding: 12px 0 0 7px;
    text-overflow: ellipsis;
    text-transform: none;
    white-space: nowrap;
  }

  .pure-menu-list {
    &.pull-right .pure-menu-item:last-child {
      border-width: 1px 0;
    }

    .pure-menu-item {
      background-color: $tab-inactive-bg;
      border-color: $tab-border;
      border-style: solid;
      border-width: 1px 1px 1px 0;
      float: left;
      line-height: 43px;
      min-width: 100px;
      padding: 0 15px;
      text-align: center;
      transition: all 200ms ease; // sass-lint:disable-line no-transition-all
    }

    .pure-menu-item a {
      background-color: transparent;
      padding: 0;
      transition: all 200ms ease; // sass-lint:disable-line no-transition-all
    }

    .pure-menu-item,
    .pure-menu-item a {
      color: $tab-inactive-text;
      font-family: $roboto;
      font-size: calc-font-size(14px);
      font-weight: 500;
    }

    .pure-menu-item:hover,
    .pure-menu-item.pure-menu-selected,
    .pure-menu-item.pure-menu-selected:hover {
      background-color: $tab-bg;
      border-bottom-color: $tab-bg;
      border-top-color: $tab-bg;
      color: $tab-text;
    }

    .pure-menu-item:hover a,
    .pure-menu-item.pure-menu-selected a,
    .pure-menu-item.pure-menu-selected:hover a {
      background-color: transparent;
      color: $tab-text;
    }

    .pure-menu-item.pure-menu-disabled {
      @include opacity(0.7);
      background-color: $tab-inactive-bg;
      color: $tab-inactive-text;
    }

    .pure-menu-item.pure-menu-disabled:hover a {
      background-color: $tab-inactive-bg;
      border-color: $tab-border;
      color: $tab-inactive-text;
      cursor: not-allowed;
    }
  }
}

.pure-menu-content {
  background-color: $tab-bg;
  line-height: 45px;
  margin-bottom: 6px;
  min-height: 45px;

  h2 {
    font-family: $roboto;
    font-size: calc-font-size(18px);
    font-weight: 500;
    line-height: 45px;
    margin: 0;
    padding: 0 20px;
  }
}

// Breadcrumbs
.pure-breadcrumbs {
  font-weight: 300;
  margin: 0 0 25px;
  padding: 0;

  li {
    display: inline-block;
    padding-right: 3px;

    &:last-child {
      font-weight: 500;
    }

    a {
      color: $txt-color;
      text-decoration: none;
    }

    a:hover {
      @include opacity(0.7);
      transition: opacity 300ms ease-in-out;
    }
  }

  .breadcrumb-sep {
    @include opacity(0.6);
    margin: 0 3px;
  }
}
