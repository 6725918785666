// sass-lint:disable no-vendor-prefixes no-duplicate-properties

// CSS3 GRADIENTS
// ---
// usage:
//     - @include css-gradient(#dfdfdf,#f8f8f8);
@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8) {
  background-color: $to;
  background-image: linear-gradient(to bottom, $from, $to);
}

// BORDER RADIUS
// ---
// usage:
//     - @include border-radius(5px);
//     - @include border-top-radius(10px);
//     - @include border-right-radius(8px);
//     - @include border-bottom-radius(10px);
//     - @include border-left-radius (6px);
@mixin border-radius($radius) {
  background-clip: border-box;
  border-radius: $radius;
}

// Single side border-radius
@mixin border-top-radius($radius) {
  background-clip: border-box;
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-right-radius($radius) {
  background-clip: border-box;
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
  background-clip: border-box;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin border-left-radius($radius) {
  background-clip: border-box;
  border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
}

// BOX SHADOW
// ---
// usage:
//     - @include box-shadow(0, 1px, 1px, 1px, rgba(0, 0, 0, 0.075), inset);
@mixin box-shadow($top, $left, $blur, $size, $color, $inset: false) {
  @if $inset {
    box-shadow: inset $top $left $blur $size $color;
  } @else {
    box-shadow: $top $left $blur $size $color;
  }
}

// BOX SHADOW
// ---
// usage:
//     - @include box-shadow-none();
@mixin box-shadow-none {
  box-shadow: none;
}

// ROTATE
// ---
// usage:
//    - @include rotate(4.5);
@mixin rotate($deg) {
  transform: rotate(#{$deg}deg);
}

// ROTATE Y
// ---
// usage:
//    - @include rotatey(4.5);
@mixin rotatey($deg) {
  transform: rotatey(#{$deg}deg);
}

// Placehoder STYLE
// ---
// usage:
//    - @include placeholder {
//         font-style:italic;
//         color: white;
//         font-weight:100;
//      }
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

// opacity STYLE
// ---
@mixin opacity($value) {
  // sass-lint:disable-block property-sort-order
  $ievalue: $value * 100;
  opacity: $value;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' + $ievalue + ')';
  filter: alpha(opacity=$ievalue);
}

@mixin filter($value) {
  filter: grayscale(1);
}
