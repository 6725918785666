.electoralprocess-voting {
  .elections {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.election-box {
  border: 1px solid $tab-acc;
  float: left;
  font-size: calc-font-size(14px);
  height: 100%;
  margin-bottom: 20px;
  padding: 30px 40px 75px;
  position: relative;
  width: 382px;

  &:nth-child(3n + 2) {
    margin-left: 20px;
    margin-right: 20px;
  }

  &.disabled {
    color: $btn-inactive-color;

    h3,
    h4,
    label {
      color: $btn-inactive-color;
    }
  }

  h3 {
    border-bottom: 1px solid $tab-acc;
    color: $txt-color;
    font-family: $roboto;
    font-size: calc-font-size(17px);
    font-weight: 500;
    margin: 0 0 11px;
    padding-bottom: 20px;
  }

  h4 {
    font-family: $roboto;
    font-weight: 500;
    margin: 0 0 6px;
  }

  .description {
    border-bottom: 1px solid $tab-acc;
    font-size: calc-font-size(15px);
    margin-bottom: 14px;
    padding: 0 6px 12px;
  }

  label {
    color: $frm-input-label;
    margin-right: 5px;
  }

  .date {
    label {
      margin-bottom: 0;
    }
  }

  .pure-button {
    bottom: 25px;
    left: 40px;
    position: absolute;
    right: 40px;
  }
}

.voting-navigation {
  bottom: 30px;
  left: 30px;
  position: absolute;
  right: 30px;
}

.candidate-box {
  @include border-radius(5px);
  border: 1px solid $tab-acc;
  height: 87px;
  margin-bottom: 10px;
  overflow: hidden;

  &[data-vote-option] {
    cursor: pointer;
  }

  &.selected {
    background-color: $alert-dk-info;
    color: $btn-color;
  }

  &.disabled {
    border-color: $btn-inactive-bg;
    color: $btn-inactive-color;

    .pure-checkbox > input + span {
      border-color: $btn-inactive-bg;
    }
  }

  .candidate-logo {
    background-color: $btn-color;
    float: left;
    margin-right: 85px;
  }

  .candidate-designation {
    display: flex;
    flex-direction: column;
    float: left;
    height: 87px;
    justify-content: center;
    width: 680px;
  }

  .pure-checkbox {
    float: right;

    &.no-selectable {
      & > input + span {
        cursor: auto;
      }
    }

    & > input + span {
      border-color: $tab-acc;
      height: 60px;
      margin-right: 13px;
      width: 60px;
    }

    & > input:checked + span {
      background-image: none;
    }

    & > input:checked + span::after,
    & > input:checked + span::before {
      border: 0.5px solid $tab-acc;
      content: '';
      left: 2px;
      position: absolute;
      top: 28px;
      width: 52px;
    }

    & > input:checked + span::before {
      transform: rotate(45deg);
    }

    & > input:checked + span::after {
      transform: rotate(-45deg);
    }
  }
}

.voting-step1 {
  .pure-form {
    margin-bottom: 60px;
  }
}

.voting-step2 {
  .click-vote {
    margin-top: 30px;
  }

  .voting-action {
    margin: 67px auto 0;
    text-align: center;
  }

  .voting-navigation {
    right: auto;
  }

  .selected-votes {
    min-height: 97px;
  }

  .candidate-box {
    display: none;
  }
}

.voting-step3 {
  .shorter-column {
    max-width: 150px;
  }

  .pin-type {
    height: 105px;

    .pure-form {
      margin-top: 26px;
    }

    .pure-button {
      margin-top: -4px;
    }
  }

  .personaldata {
    margin-top: 10px;
    text-align: center;

    a {
      font-family: $roboto;
      font-weight: 500;
    }
  }
}

.voting-step3-pin {
  .pin-box {
    margin-bottom: 110px;
    margin-top: 25px;

    .pure-form {
      margin-top: 26px;
    }

    .pure-formlabel {
      text-align: right;
    }

    .pure-button {
      margin: -4px;
    }
  }

  .voting-action {
    text-align: center;
  }
}

.voting-step4 {
  .pure-window-close {
    display: none !important; // sass-lint:disable-line no-important
  }

  .sub-title {
    font-family: $roboto;
    font-size: calc-font-size(18px);
    font-weight: 300;
    margin: 10px 0 15px;
    text-align: center;
  }

  .pure-info {
    padding-bottom: 29px;
    padding-top: 29px;
  }

  .voting-action {
    margin-top: 60px;
    text-align: center;
  }
}

.roll-search-voter {
  .search-form {
    .pure-form {
      margin-bottom: 42px;
    }
  }
}
