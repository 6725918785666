.ama-button-wrapper {
  .pure-button {
    opacity: 1;
    width: 100%;
  }

  .pure-button:hover {
    background-color: inherit;

    img {
      opacity: 0.6;
      transition: opacity 200ms ease-in-out;
    }
  }
}

.skip-ama-button {
  margin-top: 1rem;
}
